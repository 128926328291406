import styled, { css } from 'styled-components';

import { mobileCssQuerist, spacing } from 'styles/theme';

export const HeaderWrapper = styled.div`
    margin-top: ${48 * 2}px;

    ${mobileCssQuerist(css`
        margin-top: ${spacing.xxxLarge};
        margin-left: ${spacing.xxLarge};
    `)}
`;
